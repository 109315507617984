import restaurant from "./images/capitoles.jpg";
import img_card from "./images/5.jpeg";
import imgOmSuflet from "./images/header1.jpg";
import imgheader from "./images/header.webp";
import imgheadermiini from "./images/header.jpg";
import imgheadermiinimb from "./images/header.jpg";

{/*valeriacosarca@gmail.com*/}

const data = {
    introData: [{
        mire: "Nicolai",
        mireasa: "Adelina",
        data: "22 Iulie 2022",
        data_confirmare: "10 iulie 2022",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheader,
        email: "ddoros82350@gmail.com", 
       tel: "+373 621 61 882",
       phone: "tel:+37362161882",
       viber: "viber://chat?number=%2B37362161882",
       whatsapp: "https://wa.me/+37362161882",
       messenger: "https://www.messenger.com/t/diana.doros.73",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "Capitoles Park, sala Poiana",
            data: "22 iulie 2022, vineri, ora 17:00",
            adress: "str. Melestiu 13a, Chișinău",
            harta: "https://goo.gl/maps/ZgUHWGYeuBdsu5pJ8",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10883.688913764501!2d28.8483333!3d47.0025!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4f1ac37243e1b961!2sCapitoles%20Park!5e0!3m2!1sro!2s!4v1649753900300!5m2!1sro!2s"
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: "",
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgOmSuflet,
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            message1: "CU BINECUVÂNTAREA PĂRINȚILOR",
            parintii_nume1: "Vasile & Diana",
            parintii_nume2: "Ghenadie & Oxana",
            nasii: "ȘI SUSȚINEREA NAȘILOR",
            nasii_nume: "Vasile & Svetlana",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "De aici începe totul.",
            message: "\"Dragostea constă în dorința de a da ceea ce este al tău altuia și de a simți fericirea acestuia ca și cum ar fi a ta. \" – Emanuel Swdenborg",
        }
    ],

}

export default data;